import { FC, LazyExoticComponent, ReactElement, Suspense, lazy, useEffect, useState } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useOutletContext,
} from 'react-router-dom';
import Auth from '../modules/Auth';
import ErrorPage from '../modules/ErrorPage';
import Layout from '../modules/Layout';

export function useRouteContext() {
  return useOutletContext<{ setRoute: React.Dispatch<React.SetStateAction<string[]>> }>();
}

const Lazy: FC<{ element: LazyExoticComponent<FC<{}>> }> = ({ element }): ReactElement => {
  const Element: LazyExoticComponent<FC<{}>> = element;

  return (
    <Suspense fallback={<>Loading...</>}>
      <Element />
    </Suspense>
  );
};

const Router: FC<{}> = (): ReactElement => {
  const [token, setToken] = useState<string>(localStorage.getItem('token') || '');

  useEffect((): (() => void) => {
    const onStorageChange = (): void => {
      setToken(localStorage.getItem('token') || '');
    };

    window.addEventListener('storage', onStorageChange);

    return (): void => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      token !== '' ? (
        <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
          <Route index={true} element={<Lazy element={lazy(() => import('../modules/Home'))} />} />

          <Route path="/me" element={<Lazy element={lazy(() => import('../modules/Me'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Me/General'))} />} />
            <Route path="password" element={<Lazy element={lazy(() => import('../modules/Me/Password'))} />} />
          </Route>

          <Route path="/authorization" element={<Lazy element={lazy(() => import('../modules/Authorization'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Authorization/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/Authorization/Create'))} />} />
            <Route
              path=":id/update"
              element={<Lazy element={lazy(() => import('../modules/Authorization/Update'))} />}
            />
          </Route>

          <Route path="/user" element={<Lazy element={lazy(() => import('../modules/User'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/User/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/User/Create'))} />} />
            <Route path=":id/update" element={<Lazy element={lazy(() => import('../modules/User/Update'))} />} />
          </Route>

          <Route path="/reimburse" element={<Lazy element={lazy(() => import('../modules/Reimburse'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Reimburse/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/Reimburse/Create'))} />} />
            <Route path=":id/update" element={<Lazy element={lazy(() => import('../modules/Reimburse/Update'))} />} />
          </Route>

          <Route path="/project" element={<Lazy element={lazy(() => import('../modules/Project'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Project/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/Project/Create'))} />} />
            <Route path=":id">
              <Route path="detail" element={<Lazy element={lazy(() => import('../modules/Project/Detail'))} />} />
              <Route path="update" element={<Lazy element={lazy(() => import('../modules/Project/Update'))} />} />

              <Route
                path="forecast"
                element={<Lazy element={lazy(() => import('../modules/Project/Forecast/List'))} />}
              />
              <Route
                path="forecast/create"
                element={<Lazy element={lazy(() => import('../modules/Project/Forecast/Create'))} />}
              />
              <Route
                path="forecast/:forecast_id/detail"
                element={<Lazy element={lazy(() => import('../modules/Project/Forecast/Detail'))} />}
              />
              <Route
                path="forecast/:forecast_id/update"
                element={<Lazy element={lazy(() => import('../modules/Project/Forecast/Update'))} />}
              />

              <Route
                path="quotation/create"
                element={<Lazy element={lazy(() => import('../modules/Quotation/Create'))} />}
              />
              <Route
                path="quotation/:quotation_id/detail"
                element={<Lazy element={lazy(() => import('../modules/Quotation/Detail'))} />}
              />
              <Route
                path="quotation/:quotation_id/update"
                element={<Lazy element={lazy(() => import('../modules/Quotation/Update'))} />}
              />

              <Route
                path="expense"
                element={<Lazy element={lazy(() => import('../modules/Project/Expense/List'))} />}
              />
              <Route
                path="expense/create"
                element={<Lazy element={lazy(() => import('../modules/Project/Expense/Create'))} />}
              />
              <Route
                path="expense/:expense_id/update"
                element={<Lazy element={lazy(() => import('../modules/Project/Expense/Update'))} />}
              />

              <Route path="work" element={<Lazy element={lazy(() => import('../modules/Project/Work/List'))} />} />
              <Route
                path="work/create"
                element={<Lazy element={lazy(() => import('../modules/Project/Work/Create'))} />}
              />
              <Route
                path="work/:work_id/update"
                element={<Lazy element={lazy(() => import('../modules/Project/Work/Update'))} />}
              />
            </Route>
          </Route>

          <Route path="/quotation" element={<Lazy element={lazy(() => import('../modules/Quotation'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Quotation/List'))} />} />
          </Route>

          <Route path="/material" element={<Lazy element={lazy(() => import('../modules/Material'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Material/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/Material/Create'))} />} />
            <Route path=":id/update" element={<Lazy element={lazy(() => import('../modules/Material/Update'))} />} />
          </Route>

          <Route path="/uom" element={<Lazy element={lazy(() => import('../modules/Uom'))} />}>
            <Route index={true} element={<Lazy element={lazy(() => import('../modules/Uom/List'))} />} />
            <Route path="create" element={<Lazy element={lazy(() => import('../modules/Uom/Create'))} />} />
            <Route path=":id/update" element={<Lazy element={lazy(() => import('../modules/Uom/Update'))} />} />
          </Route>
        </Route>
      ) : (
        <Route path="/" element={<Auth />} errorElement={<ErrorPage />} />
      ),
    ),
  );

  return <RouterProvider router={router} />;
};

export default Router;
