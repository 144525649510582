import { useQuery } from '@tanstack/react-query';
import { FC, ReactElement } from 'react';
import {
  IoBuildOutline,
  IoCashOutline,
  IoClipboardOutline,
  IoFingerPrintOutline,
  IoFlaskOutline,
  IoHomeOutline,
  IoKeyOutline,
  IoPersonOutline,
} from 'react-icons/io5';
import { TbRuler3 } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { authorize } from '../../lib/auth';
import { ResponseError, ResponseOK } from '../../lib/http/response';
import api from '../Me/api';
import { Me } from '../Me/interface';

type MenuProps = {
  className?: string;
  onLinkClick?: Function;
};

const Menu: FC<MenuProps> = (props: MenuProps): ReactElement => {
  const me = useQuery<ResponseOK<Me>, ResponseError>(['me'], (): Promise<ResponseOK<Me>> => api['me']());

  const onLinkClick = (): void => {
    typeof props.onLinkClick === 'function' && props.onLinkClick();
  };

  return (
    <div className={`p-2 bg-white w-72 h-screen overflow-y-scroll shadow-md ${props.className}`}>
      <div className="flex flex-col justify-between h-full">
        <div className="mb-4">
          <div className="flex items-center justify-center min-h-12">
            <Link to="/">
              <IoFingerPrintOutline className="h-6 w-6" />
            </Link>
          </div>
          <ul className="menu gap-1">
            <li>
              <Link to="/" onClick={onLinkClick}>
                <IoHomeOutline className="h-6 w-6" />
                Home
              </Link>
            </li>
            {authorize(me.data, 'authorization', 'view') ? (
              <li>
                <Link to="/authorization" onClick={onLinkClick}>
                  <IoKeyOutline className="h-6 w-6" />
                  Authorization
                </Link>
              </li>
            ) : undefined}
            {authorize(me.data, 'user', 'view') ? (
              <li>
                <Link to="/user" onClick={onLinkClick}>
                  <IoPersonOutline className="h-6 w-6" />
                  User
                </Link>
              </li>
            ) : undefined}
            {authorize(me.data, 'reimburse', 'view') ? (
              <li>
                <Link to="/reimburse" onClick={onLinkClick}>
                  <IoCashOutline className="h-6 w-6" />
                  Reimburse
                </Link>
              </li>
            ) : undefined}
            {authorize(me.data, 'project', 'view') ? (
              <li>
                <Link to="/project" onClick={onLinkClick}>
                  <IoBuildOutline className="h-6 w-6" />
                  Project
                </Link>
              </li>
            ) : undefined}
            {authorize(me.data, 'quotation', 'view') ? (
              <li>
                <Link to="/quotation" onClick={onLinkClick}>
                  <IoClipboardOutline className="h-6 w-6" />
                  Quotation
                </Link>
              </li>
            ) : undefined}
            {authorize(me.data, 'material', 'view') ? (
              <li>
                <Link to="/material" onClick={onLinkClick}>
                  <IoFlaskOutline className="h-6 w-6" />
                  Material
                </Link>
              </li>
            ) : undefined}
            <li>
              <Link to="/uom" onClick={onLinkClick}>
                <TbRuler3 className="h-6 w-6" />
                Uom
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
