import { FC, ReactElement } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';

const ErrorPage: FC<{}> = (): ReactElement => {
  const err: unknown = useRouteError();
  let message: string;

  if (isRouteErrorResponse(err)) {
    // err is type `ErrorResponse`
    message = err.error?.message || err.statusText;
  } else if (err instanceof Error) {
    message = err.message;
  } else if (typeof err === 'string') {
    message = err;
  } else {
    console.error(err);
    message = 'Unknown error';
  }

  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
