import { Authorization } from '../../modules/Authorization/interface';
import { Me } from '../../modules/Me/interface';
import { Status as ProjectStatus } from '../../modules/Project/enum';
import { Status as ReimburseStatus } from '../../modules/Reimburse/enum';
import { ResponseOK } from '../http/response';

export const authorize: Function = (me: ResponseOK<Me>, module: string, action: string): boolean => {
  if (me) {
    if (me.data.authorization) {
      const auth: Authorization = me.data.authorization;

      if (auth.super) {
        return true;
      }

      if (module in auth.actions) {
        if (action in auth.actions[module]) {
          return auth.actions[module][action];
        }
      }
    }
  }

  return false;
};

export const canUpdateProjectStatus: Function = (me: ResponseOK<Me>, status: string): boolean => {
  if (me) {
    if (status === ProjectStatus.pending) {
      return me.data.authorization?.super;
    } else {
      return true;
    }
  }

  return false;
};

export const lockProjectModule: Function = (module: string, status: string): boolean => {
  if (module === 'forecast') {
    if (status === ProjectStatus.pending) return false;
  } else if (module === 'expense' || module === 'work') {
    if (status === ProjectStatus.onProgress) return false;
  }

  return true;
};

export const lockReimburseModule: Function = (status: string): boolean => {
  return status !== ReimburseStatus.pending;
};
