import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import { ResponseError } from './response';

const http: Function = (opts: CreateAxiosDefaults): AxiosInstance => {
  const client: AxiosInstance = axios.create(opts);

  client.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return config;
  });

  client.interceptors.response.use(
    (res: AxiosResponse): any => {
      return { ...res.data };
    },
    (err: AxiosError): Promise<ResponseError> => {
      let response: ResponseError = {};

      // Handling error with HTTP response.
      if (err.response) {
        if (err.response.status === 500) {
          //
        } else {
          response = err.response.data as ResponseError;
        }
      } else {
        // Handle client error like net:ERR_CONNECTION_REFUSED, etc.
      }

      return Promise.reject(response);
    },
  );

  return client;
};

export { http };
