import { useQuery } from '@tanstack/react-query';
import { FC, ReactElement, useState } from 'react';
import { Button, Drawer, Dropdown, Navbar } from 'react-daisyui';
import { HiMenuAlt1 } from 'react-icons/hi';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom';
import Menu from './Menu';
import { toast } from '../../lib/dialog';
import { ResponseError, ResponseOK } from '../../lib/http/response';
import api from '../Me/api';
import { Me } from '../Me/interface';

const Layout: FC<{}> = (): ReactElement => {
  const navigate: NavigateFunction = useNavigate();

  // State
  const [open, setOpen] = useState<boolean>(false);

  const me = useQuery<ResponseOK<Me>, ResponseError>(['me'], (): Promise<ResponseOK<Me>> => api['me']());

  // Handler
  const logout = () => {
    localStorage.setItem('token', '');
    toast({
      title: 'Logout success',
    });
    window.dispatchEvent(new Event('storage'));
    navigate('/');
  };

  return (
    <div className="w-full lg:pl-72">
      <Menu className="fixed start-0 z-30 hidden lg:block" onLinkClick={(): void => setOpen(false)} />
      <Drawer
        open={open}
        side={<Menu className="block" onLinkClick={(): void => setOpen(false)} />}
        onClickOverlay={(): void => setOpen(false)}
        className="min-h-screen"
      >
        <Navbar className="px-3 lg:px-6 shadow-md">
          <Navbar.Start>
            <div className="flex-none lg:hidden">
              <Button shape="square" color="ghost" onClick={(): void => setOpen(true)}>
                <HiMenuAlt1 className="w-6 h-6" />
              </Button>
            </div>
            <h3 className="hidden lg:block text-lg font-bold">Growtech</h3>
          </Navbar.Start>
          <Navbar.End>
            <Dropdown className="z-20" end={true} vertical="bottom">
              <Button color="ghost">
                <span className="flex justify-center items-center">
                  <IoPersonCircleOutline className="w-8 h-8 mr-2" />
                  <span>{me ? me.data?.data.name : 'Admin'}</span>
                </span>
              </Button>
              <Dropdown.Menu className="bg-white w-52 border border-gray-100 shadow-md ">
                <Dropdown.Item onClick={(): void => navigate('/me')}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={(): void => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.End>
        </Navbar>

        <div className="w-screen lg:w-[calc(100vw-18rem)] min-h-[calc(100vh-4rem)] py-4 px-6">
          <Outlet />
        </div>
      </Drawer>
    </div>
  );
};

export default Layout;
