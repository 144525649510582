import { AxiosInstance } from 'axios';
import { AuthRequest, AuthResponse } from './interface';
import { http } from '../../lib/http';
import { ResponseOK } from '../../lib/http/response';

const request: AxiosInstance = http({ baseURL: process.env.REACT_APP_API_URL });

const api: { [key: string]: Function } = {
  'auth.login': (req: AuthRequest): Promise<ResponseOK<AuthResponse>> => {
    return request.post('/dashboard', req);
  },
};

export default api;
