export enum ExpenseType {
  material = 'Material',
  production = 'Production',
  labor = 'Labor',
  finishing = 'Finishing',
  installation = 'Installation',
  logistic = 'Logistic',
  others = 'Others',
}

export enum Status {
  pending = 'Pending',
  approved = 'Approved',
  onProgress = 'On-Progress',
  finished = 'Finished',
  paid = 'Paid',
  cancelled = 'Cancelled',
}

export enum WorkType {
  printing = 'Printing',
  nonPrinting = 'Non-Printing',
  combination = 'Combination',
}
