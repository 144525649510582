import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const swal = withReactContent(Swal);

export type DialogParams = {
  title: string;
  text?: string;
  icon?: SweetAlertIcon;
  callback?: (res: SweetAlertResult) => {};
};

export const warning: Function = (params: DialogParams): void => {
  swal
    .fire({
      title: params.title,
      text: params.text,
      icon: 'warning',
      confirmButtonColor: '#36d399',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: '#f87272',
    })
    .then((res: SweetAlertResult): void => {
      params.callback !== undefined && params.callback(res);
    });
};

export const toast: Function = (params: DialogParams): void => {
  swal.fire({
    title: params.title,
    icon: params.icon === undefined ? 'success' : params.icon,
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};
