import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './app/Router';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'moment/locale/id';

moment.locale('id');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      retry: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
