import { useMutation } from '@tanstack/react-query';
import { FC, ReactElement } from 'react';
import { Button, Card, Input } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import api from './api';
import { AuthRequest, AuthResponse } from './interface';
import { toast } from '../../lib/dialog';
import { ResponseError, ResponseOK } from '../../lib/http/response';

const Auth: FC<{}> = (): ReactElement => {
  const authLogin = useMutation<ResponseOK<AuthResponse>, ResponseError, AuthRequest>(
    ['auth.login'],
    (request: AuthRequest): Promise<ResponseOK<AuthResponse>> => api['auth.login'](request),
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AuthRequest>();

  // Handler
  const handlerSubmit = async (req: AuthRequest) => {
    authLogin.mutate(req, {
      onSuccess: (res: ResponseOK<AuthResponse>): void => {
        localStorage.setItem('token', res.data.token);
        window.dispatchEvent(new Event('storage'));
        toast({
          title: 'Authenticated',
        });
      },
      onError: (e: ResponseError): void => {
        toast({
          title: e.message,
          icon: 'error',
        });
      },
    });
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <Card className="w-full max-w-lg shadow-xl">
        <form onSubmit={handleSubmit(handlerSubmit)}>
          <Card.Body>
            <Card.Title tag="h1">Welcome</Card.Title>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <Input
                size="md"
                autoComplete="email"
                autoFocus={true}
                className="mb-2"
                disabled={authLogin.isLoading}
                placeholder="johndoe@example.com"
                type="email"
                {...register('email', {
                  required: 'Harap isi email',
                })}
              />
              {errors?.email && <span className="text-sm text-red-600">{errors.email.message}</span>}
            </div>
            <div className="form-control w-full mb-4">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <Input
                size="md"
                autoComplete="current-password"
                className="mb-2"
                disabled={authLogin.isLoading}
                placeholder="Password"
                type="password"
                {...register('password', {
                  required: 'Harap isi password',
                })}
              />
              {errors?.password && <span className="text-sm text-red-600">{errors.password.message}</span>}
            </div>

            <Card.Actions className="justify-end">
              <Button color="primary" disabled={authLogin.isLoading} type="submit">
                {authLogin.isLoading && <span className="loading loading-spinner loading-sm text-white" />}
                Sign in
              </Button>
            </Card.Actions>
          </Card.Body>
        </form>
      </Card>
    </div>
  );
};

export default Auth;
