import { AxiosInstance } from 'axios';
import { Me, UpdateGeneral, UpdatePassword } from './interface';
import { http } from '../../lib/http';
import { ResponseOK } from '../../lib/http/response';

const request: AxiosInstance = http({ baseURL: `${process.env.REACT_APP_API_URL}/dashboard` });

const api: { [key: string]: Function } = {
  me: (): Promise<ResponseOK<Me>> => {
    return request.get('/me');
  },
  'me.update': (req: UpdateGeneral): Promise<ResponseOK<void>> => {
    const form = new FormData();
    form.append('name', req.name);
    form.append('email', req.email);
    form.append('_method', 'patch');

    if (req.signature.length > 0) {
      form.append('signature', req.signature[0]);
    }

    return request.post(`/me`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    // return request.patch('/me', { name: req.name, email: req.email });
  },
  'me.password': (req: UpdatePassword): Promise<ResponseOK<void>> => {
    return request.patch('/me/password', {
      current: req.current,
      password: req.password,
      password_confirmation: req.confirm,
    });
  },
};

export default api;
